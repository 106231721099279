@charset "utf-8";
@import "../css/_include.scss";
/*-------------------------------------------------------------------
index.html
-------------------------------------------------------------------*/
.grid {
	display: flex;
	flex-wrap: wrap;
	&._col2_ {
		.__col {
			flex-basis: calc((100% - 40px) / 2);
			&:not(:nth-child(2n+1)) {
				margin-left: 40px;
			}
			&:not(:nth-child(-n+2)) {
				margin-top: 20px;
			}
			img {
				max-width: 100%;
			}
		}
	}
}
.index-menu {
	&__item {
		background-color:#FFF;
		@include box-shadow-black();
		margin-bottom:1.5em;
		position:relative;
	}
	&__link {
		display: flex;
		text-decoration: none;
		transition: .4s all;
		&:hover {
			opacity: 0.7;
		}
	}
	&__img {
		flex: 0 0 190px;
		margin: 0 30px 0 0;
	}
	&__body {
		flex: 1 1 auto;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding: 15px 15px 15px 0;
	}
	&__ttl {
		font-size: 2.8rem;
		text-align: center;
		line-height: 1.5;
		position: relative;
		padding: 0 0 0 15px;
		&::before {
			background: url("/img/top/ico_yajirushi.svg") no-repeat;
			background-size: 5px 10px;
			width: 5px;
			height: 10px;
			content: "";
			position: absolute;
			left: 0;
			top: 50%;
			transform: translate(0, -50%);
		}
	}
	&__txt {
		margin: 0;
	}
}

.index-symptom {
	background: #FBFAF6;
	&List {
		display: flex;
		flex-wrap: wrap;
		&__item {
			flex-basis: calc((100% - 100px) / 6);
			background-color:#FFF;
			text-align: center;
			@include box-shadow-black();
			&:not(:nth-child(6n+1)) {
				margin-left: 20px;
			}
			&:not(:nth-child(-n+6)) {
				margin-top: 20px;
			}
			&._list_ {
				background: #F9ABAB;
				color: #fff;
				.index-symptomList__link {
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					&::after {
						background: url("/img/top/ico_yajirushi_03.svg") no-repeat;
					}
				}
			}
		}
		&__link {
			position: relative;
			padding: 20px 15px 50px;
			width: 100%;
			height: 100%;
			text-decoration: none;
			display: block;
			&::after {
				background: url("/img/top/ico_yajirushi_02.svg") no-repeat;
				background-size: 20px 20px;
				width: 20px;
				height: 20px;
				position: absolute;
				left: 50%;
				transform: translate(-50%, 0);
				bottom: 15px;
				content: "";
			}
			.__img {
				margin: 0 0 15px;
			}
		}
	}
}

.index-bnr {
	background: #FFF1F1;
	&__item {
		&._right_ {
			display: flex;
			.__youtube {
				margin: 0 0 0 20px;
			}
			.__instgram,
			.__youtube {
				width: calc((100% - 20px) / 2);
			}
		}
	}
}

@include sp {
	.grid {
		align-items: inherit;
		&._col2_ {
			.__col {
				flex-basis: calc((100% - 20px) / 2);
				&:not(:nth-child(2n+1)) {
					margin-left: 20px;
				}
			}
		}
		&._sp-col1_ {
			.__col {
				flex: 0 0 100%;
				width: 100%;
				&:not(:nth-child(2n+1)) {
					margin-left: 0;
				}
				&:not(:nth-child(-n+1)) {
					margin-top: 20px;
				}
			}
		}
	}
	.index-menu {
		&__item {
			margin-bottom: 0;
		}
		&__link {
			display: block;
		}
		&__img {
			flex: 0 0 100%;
			margin: 0;
		}
		&__body {
			display: block;
			padding: 15px 10px;
		}
		&__ttl {
			font-size: 1.6rem;
			padding: 0 0 0 10px;
			text-align: left;
			&::before {
				top: 0.7em;
			}
		}
		&__txt {
			margin: 0;
		}
	}
	.index-symptom {
		&List {
			&__item {
				flex-basis: calc((100% - 20px) / 2);
				background-color:#FFF;
				text-align: center;
				&:not(:nth-child(6n+1)) {
					margin-left: 0;
				}
				&:not(:nth-child(2n+1)) {
					margin-left: 20px;
				}
				&:not(:nth-child(-n+2)) {
					margin-top: 20px;
				}
			}
		}
	}
}

@include sp-s {
	.grid {
		&._col2_ {
			.__col {
				flex-basis: calc((100% - 20px) / 2);
				&:not(:nth-child(2n+1)) {
					margin-left: 20px;
				}
			}
		}
		&._sp-col1_ {
			.__col {
				flex: 0 0 100%;
				width: 100%;
				&:not(:nth-child(2n+1)) {
					margin-left: 0;
				}
				&:not(:nth-child(-n+1)) {
					margin-top: 20px;
				}
			}
		}
	}
	.index-menu {
		&__item {
			margin-bottom: 0;
		}
		&__link {
			display: block;
		}
		&__img {
			flex: 0 0 100%;
			margin: 0;
		}
		&__body {
			display: block;
			padding: 15px 10px;
		}
		&__ttl {
			font-size: 1.6rem;
			padding: 0 0 0 10px;
			text-align: left;
			&::before {
				top: 0.7em;
			}
		}
		&__txt {
			margin: 0;
		}
	}
	.index-symptom {
		&List {
			&__item {
				flex-basis: calc((100% - 20px) / 2);
				background-color:#FFF;
				text-align: center;
				&:not(:nth-child(6n+1)) {
					margin-left: 0;
				}
				&:not(:nth-child(2n+1)) {
					margin-left: 20px;
				}
				&:not(:nth-child(-n+2)) {
					margin-top: 20px;
				}
			}
		}
	}
}