@charset "utf-8";
//font-face
@mixin fontface($name, $path, $weight: null, $style: null, $format1: woff2, $format2: woff, $display: swap) {
  $src: null;
  @font-face {
    font-family: $name;
    src: url($path+$format1) format($format1),url($path+$format2) format($format2);
    font-weight: $weight;
    font-style:  $style;
    font-display: $display;
  }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//color
$color-main:#333;
$color-red:#f26672;
$color-blue:#444654;
$color-orange:#ec652a;
$color-navy:#1c3a70;
$color-brown:#b69852;
$color-pink:#ff6666;
$color-green:#99cc33;
//font
$font-base-sp:14px;
$font-base-pc:18px;
//text
$text-sans-serif:"游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
$text-serif:"游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "Noto Serif Japanese", serif;
$text-font-awesome:"Font Awesome 5 Free";
$text-font-en:'Dancing Script', cursive;
//window
$min-width-pc:1240px;
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//mixin
@mixin pc {
  @media (min-width: 768px) {
    @content;
  }
}
@mixin sp {
  @media (max-width: 767px) {
    @content;
  }
}
@mixin sp-s {
  @media (max-width: 375px) {
    @content;
  }
}
@mixin font-size($size, $base: 10) {
  font-size: $size + px;
  font-size: ($size / $base) + rem;
}
@mixin flex {
  display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}
@mixin flex-justify-content($value:start) {
  -webkit-justify-content: $value;
	-ms-justify-content: $value;
	justify-content: $value;
}
@mixin flex-align-items($value:stretch) {
  -webkit-align-items: $value;
	-ms-align-items: $value;
	align-items: $value;
}
@mixin flex-align-items-start {
  -webkit-align-items: start;
	-ms-align-items: start;
	align-items: flex-start;
}
@mixin flex-align-items-end {
  -webkit-align-items: end;
	-ms-align-items: end;
	align-items: flex-end;
}
@mixin flex-wrap($value:nowrap) {
  -webkit-flex-wrap: $value;
	-ms-flex-wrap: $value;
	flex-wrap: $value;
}
@mixin flex-direction($value:row) {
  -webkit-flex-direction: $value;
	-ms-flex-direction: $value;
	flex-direction: $value;
}
@mixin box-shadow-black($x:0,$y:1px,$grow:4px,$offset:0,$alpha:.15) {
  box-shadow:$x $y $grow $offset rgba(0,0,0,$alpha);
}
@mixin clearfix {
  content: "";
	display: block;
	clear: both;
}