/*-------------------------------------------------------------------
index.html
-------------------------------------------------------------------*/
.grid {
  display: flex;
  flex-wrap: wrap;
}

.grid._col2_ .__col {
  flex-basis: calc((100% - 40px) / 2);
}

.grid._col2_ .__col:not(:nth-child(2n+1)) {
  margin-left: 40px;
}

.grid._col2_ .__col:not(:nth-child(-n+2)) {
  margin-top: 20px;
}

.grid._col2_ .__col img {
  max-width: 100%;
}

.index-menu__item {
  background-color: #FFF;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  margin-bottom: 1.5em;
  position: relative;
}

.index-menu__link {
  display: flex;
  text-decoration: none;
  transition: .4s all;
}

.index-menu__link:hover {
  opacity: 0.7;
}

.index-menu__img {
  flex: 0 0 190px;
  margin: 0 30px 0 0;
}

.index-menu__body {
  flex: 1 1 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 15px 15px 15px 0;
}

.index-menu__ttl {
  font-size: 2.8rem;
  text-align: center;
  line-height: 1.5;
  position: relative;
  padding: 0 0 0 15px;
}

.index-menu__ttl::before {
  background: url("/img/top/ico_yajirushi.svg") no-repeat;
  background-size: 5px 10px;
  width: 5px;
  height: 10px;
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
}

.index-menu__txt {
  margin: 0;
}

.index-symptom {
  background: #FBFAF6;
}

.index-symptomList {
  display: flex;
  flex-wrap: wrap;
}

.index-symptomList__item {
  flex-basis: calc((100% - 100px) / 6);
  background-color: #FFF;
  text-align: center;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
}

.index-symptomList__item:not(:nth-child(6n+1)) {
  margin-left: 20px;
}

.index-symptomList__item:not(:nth-child(-n+6)) {
  margin-top: 20px;
}

.index-symptomList__item._list_ {
  background: #F9ABAB;
  color: #fff;
}

.index-symptomList__item._list_ .index-symptomList__link {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.index-symptomList__item._list_ .index-symptomList__link::after {
  background: url("/img/top/ico_yajirushi_03.svg") no-repeat;
}

.index-symptomList__link {
  position: relative;
  padding: 20px 15px 50px;
  width: 100%;
  height: 100%;
  text-decoration: none;
  display: block;
}

.index-symptomList__link::after {
  background: url("/img/top/ico_yajirushi_02.svg") no-repeat;
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 15px;
  content: "";
}

.index-symptomList__link .__img {
  margin: 0 0 15px;
}

.index-bnr {
  background: #FFF1F1;
}

.index-bnr__item._right_ {
  display: flex;
}

.index-bnr__item._right_ .__youtube {
  margin: 0 0 0 20px;
}

.index-bnr__item._right_ .__instgram,
.index-bnr__item._right_ .__youtube {
  width: calc((100% - 20px) / 2);
}

@media (max-width: 767px) {
  .grid {
    align-items: inherit;
  }
  .grid._col2_ .__col {
    flex-basis: calc((100% - 20px) / 2);
  }
  .grid._col2_ .__col:not(:nth-child(2n+1)) {
    margin-left: 20px;
  }
  .grid._sp-col1_ .__col {
    flex: 0 0 100%;
    width: 100%;
  }
  .grid._sp-col1_ .__col:not(:nth-child(2n+1)) {
    margin-left: 0;
  }
  .grid._sp-col1_ .__col:not(:nth-child(-n+1)) {
    margin-top: 20px;
  }
  .index-menu__item {
    margin-bottom: 0;
  }
  .index-menu__link {
    display: block;
  }
  .index-menu__img {
    flex: 0 0 100%;
    margin: 0;
  }
  .index-menu__body {
    display: block;
    padding: 15px 10px;
  }
  .index-menu__ttl {
    font-size: 1.6rem;
    padding: 0 0 0 10px;
    text-align: left;
  }
  .index-menu__ttl::before {
    top: 0.7em;
  }
  .index-menu__txt {
    margin: 0;
  }
  .index-symptomList__item {
    flex-basis: calc((100% - 20px) / 2);
    background-color: #FFF;
    text-align: center;
  }
  .index-symptomList__item:not(:nth-child(6n+1)) {
    margin-left: 0;
  }
  .index-symptomList__item:not(:nth-child(2n+1)) {
    margin-left: 20px;
  }
  .index-symptomList__item:not(:nth-child(-n+2)) {
    margin-top: 20px;
  }
}

@media (max-width: 375px) {
  .grid._col2_ .__col {
    flex-basis: calc((100% - 20px) / 2);
  }
  .grid._col2_ .__col:not(:nth-child(2n+1)) {
    margin-left: 20px;
  }
  .grid._sp-col1_ .__col {
    flex: 0 0 100%;
    width: 100%;
  }
  .grid._sp-col1_ .__col:not(:nth-child(2n+1)) {
    margin-left: 0;
  }
  .grid._sp-col1_ .__col:not(:nth-child(-n+1)) {
    margin-top: 20px;
  }
  .index-menu__item {
    margin-bottom: 0;
  }
  .index-menu__link {
    display: block;
  }
  .index-menu__img {
    flex: 0 0 100%;
    margin: 0;
  }
  .index-menu__body {
    display: block;
    padding: 15px 10px;
  }
  .index-menu__ttl {
    font-size: 1.6rem;
    padding: 0 0 0 10px;
    text-align: left;
  }
  .index-menu__ttl::before {
    top: 0.7em;
  }
  .index-menu__txt {
    margin: 0;
  }
  .index-symptomList__item {
    flex-basis: calc((100% - 20px) / 2);
    background-color: #FFF;
    text-align: center;
  }
  .index-symptomList__item:not(:nth-child(6n+1)) {
    margin-left: 0;
  }
  .index-symptomList__item:not(:nth-child(2n+1)) {
    margin-left: 20px;
  }
  .index-symptomList__item:not(:nth-child(-n+2)) {
    margin-top: 20px;
  }
}
